import React, { createContext, useCallback, useState, useContext } from 'react';
import { isBefore } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { singIn } from 'services/api/SignIn';
import api from 'services/api';
import { IAuthContextData, IAuthState, IUser } from './types';

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem('@imoveis:token');
    const tokenExpires = localStorage.getItem('@imoveis:tokenExpires');
    const user = localStorage.getItem('@imoveis:user');
    const permissions = localStorage.getItem('@imoveis:permissions');

    if (!tokenExpires) {
      // Para evitar erros caso não tenha tokenExpires
      localStorage.removeItem('@imoveis:token');
      localStorage.removeItem('@imoveis:tokenExpires');
      localStorage.removeItem('@imoveis:user');
      localStorage.removeItem('@imoveis:permissions');

      return {} as IAuthState;
    }

    if (
      tokenExpires &&
      isBefore(
        zonedTimeToUtc(tokenExpires, 'America/Sao_Paulo'),
        zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
      )
    ) {
      localStorage.removeItem('@imoveis:token');
      localStorage.removeItem('@imoveis:tokenExpires');
      localStorage.removeItem('@imoveis:user');
      localStorage.removeItem('@imoveis:permissions');

      return {} as IAuthState;
    }

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        user: JSON.parse(user),
        permissions: permissions ? JSON.parse(permissions) : [],
      };
    }

    return {} as IAuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await singIn({ email, password });

    const { token, user, expires_in, permissions } = response;

    localStorage.setItem('@imoveis:token', token);
    localStorage.setItem('@imoveis:tokenExpires', expires_in);
    localStorage.setItem('@imoveis:user', JSON.stringify(user));
    localStorage.setItem('@imoveis:permissions', JSON.stringify(permissions));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user, permissions });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@imoveis:token');
    localStorage.removeItem('@imoveis:tokenExpires');
    localStorage.removeItem('@imoveis:user');
    localStorage.removeItem('@imoveis:permissions');

    setData({} as IAuthState);
  }, []);

  const updateUser = useCallback(
    (user: IUser) => {
      setData({
        ...data,
        token: data.token,
        user,
      });
      localStorage.setItem('@imoveis:user', JSON.stringify(user));
    },
    [setData, data],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        permissions: data.permissions,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
